/* global ajax_object */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import Isotope from 'isotope-layout';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Lock body scroll
 */

function bodyScrollLock(status) {
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  window.onscroll = function () {};

  if (status === true) {
    // Check window scroll exists else use traditional method
    if (window.onscroll !== null) {
      // if any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    }
  } else {
    //body.classList.remove("fixed", "overflow-y-scroll");
    window.onscroll = function () {};
  }
}

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Header hover function
 */
function headerHover() {
  if ($(window).width() > 1024) {
    $('.js-header').hover(
      function () {
        $(this).addClass('active');
      },
      function () {
        let $header = $(this);
        $header.removeClass('active');
      }
    );
  }
}

/**
 * Scroll down button
 */

function stageScrollDown() {
  $('body').on('click', '.js-scroll-down', function () {
    $('html, body').animate(
      { scrollTop: $(this).closest('.stage').next().offset().top },
      1000,
      'swing'
    );
  });
}

/**
 * History slider
 */
function historySlider() {
  let $slider = $('.js-history-slider'),
    $point = $('.js-history-point'),
    $indicator = $('.js-slider-indicator'),
    $line = $('.js-history-line'),
    $prev = $slider.closest('.history__slider').find('.js-btn-prev'),
    $next = $slider.closest('.history__slider').find('.js-btn-next'),
    offsetLeft = 0;

  let $pointSlider = $('.js-history-points');

  $pointSlider.each(function (i, slider) {
    $(slider).slick({
      slidesToShow: 8,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      infinite: false,
      speed: 500,
      fade: false,
      focusOnSelect: true,
      cssEase: 'linear',
      asNavFor: $slider,
      rows: 0,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });

  if ($slider.length) {
    $slider.each(function (i, slider) {
      $(slider).slick({
        dots: false,
        infinite: false,
        speed: 500,
        fade: false,
        cssEase: 'linear',
        asNavFor: $pointSlider,
        prevArrow: $prev,
        nextArrow: $next,
      });

      $(slider).on(
        'beforeChange',
        function (event, slick, currentSlide, nextSlide) {
          offsetLeft = $point[nextSlide].offsetLeft;

          if (window.innerWidth > 1024) {
            $indicator.css('left', offsetLeft + 'px');
          } else {
            let offset;
            if (nextSlide > 3) {
              offset = $point[currentSlide].offsetLeft;
            } else {
              offset = offsetLeft % 2;
            }

            $line.css('transform', 'translateX(-' + offsetLeft / 2 + 'px)');
            $indicator.css('left', offset + 'px');
          }
        }
      );
    });
  }
}

/**
 * Terminals slider
 */
function terminalSlider() {
  let $slider = $('.js-terminal-slider'),
    $prev = $slider.closest('.terminals__slider').find('.js-btn-prev'),
    $next = $slider.closest('.terminals__slider').find('.js-btn-next');

  if ($slider.length) {
    $slider.each(function (i, slider) {
      $(slider).slick({
        dots: false,
        infinite: false,
        speed: 500,
        fade: false,
        cssEase: 'linear',
        prevArrow: $prev,
        nextArrow: $next,
      });
    });
  }
}

/**
 * Grains grid hover
 */
function grainGridItemHeight() {
  let gridHeight = $('.js-grains-grid').outerHeight(),
    $item = $('.js-grain-item'),
    itemHeight = $item.outerHeight();
  let height;

  if (window.innerWidth > 1024) {
    height = gridHeight - itemHeight;
  } else {
    height = itemHeight + 16;
  }

  document.documentElement.style.setProperty(
    '--grain-info-height',
    `${height}px`
  );

  if (window.innerWidth > 1024) {
    $($item).hover(
      function () {
        $(this).addClass('active visible');
      },
      function () {
        let $gridItem = $(this);
        $gridItem.removeClass('visible');
        setTimeout(function () {
          $gridItem.removeClass('active');
        }, 250);
      }
    );
  } else {
    $('body').on('click', '.js-grain-item', function () {
      if ($(this).hasClass('active visible')) {
        $(this).removeClass('visible');
        let $gridItem = $(this);
        $gridItem.removeClass('visible');
        setTimeout(function () {
          $gridItem.removeClass('active');
        }, 250);
      } else {
        $(this).addClass('active visible');
      }
    });
  }
}

/**
 * Grains grid masonry
 */
function masonryGrainsGrid() {
  let grid = document.querySelector('.js-grains-masonry');

  new Isotope(grid, {
    layoutMode: 'masonry',
    itemSelector: '.js-grains-masonry-item',
    percentPosition: true,
    masonry: {
      columnWidth: 1,
      gutter: 1,
    },
  });
}

/**
 * Team filter
 */
function teamFilter() {
  $('.js-categories').on('click', 'button', function () {
    $('.js-categories button').removeClass('active');
    $('.js-load-members').val(2);
    $(this).addClass('active');

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      dataType: 'json',
      data: {
        action: 'team_filter',
        team_category: $(this).val(),
      },
    }).done(function (response) {
      $('.js-members').html(response.template);
      if (response.quantity < 5) {
        $('.js-load-members').addClass('hide');
      } else {
        $('.js-load-members').removeClass('hide');
      }
    });
  });

  $('.js-load-members').on('click', function () {
    let team_category = $('.js-categories').find('.active').val();

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      dataType: 'json',
      data: {
        action: 'team_filter',
        team_category,
        paged: $(this).val(),
      },
    }).done(function (response) {
      $('.js-members').append(response.template);
      if (response.currentPage !== 0) {
        $('.js-load-members').val(response.currentPage);
      } else {
        $('.js-load-members').addClass('hide');
      }
    });
  });
}

/**
 * Toggle Offers item
 */
function toggleOffersItem() {
  $('body').on('click', '.js-offers-toggle', function () {
    $(this)
      .closest('.js-offers-item')
      .toggleClass('active')
      .find('.js-hidden')
      .slideToggle(400);
  });
}

/**
 * Load more Offers
 */
function loadMoreOffers() {
  $('body').on('click', '.js-load-offers', function () {
    let exclude = $('.js-offers').attr('data-offers');

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      dataType: 'json',
      data: {
        action: 'load_offers',
        exclude,
        paged: $(this).val(),
      },
    }).done(function (response) {
      $('.js-offers').append(response.template);

      if (response.currentPage !== 0) {
        $('.js-load-offers').val(response.currentPage);
      } else {
        $('.js-load-offers').addClass('hide');
      }

      $('.matchHeight').matchHeight();
    });
  });
}

/**
 * News slider
 */
function newsSlider() {
  let $slider = $('.js-news-slider');

  if ($slider.length) {
    $slider.each(function (i, slider) {
      $(slider).slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        fade: false,
        cssEase: 'ease',
        rows: 0, // Prevent generating extra markup
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      });
    });
  }
}

/**
 * Show News modal content
 */
function newsModalContent() {
  $('body').on('click', '.js-modal-news-show', function () {
    let newsId = $(this).val();
    $('.js-modal-news').addClass('active');

    if (window.innerWidth < 1025) {
      bodyScrollLock(true);
    }

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      dataType: 'json',
      data: {
        action: 'load_news',
        newsId,
      },
    }).done(function (response) {
      $('.js-modal-content').html(response.template);
      $('.js-modal-preloader').removeClass('visible');
    });
  });

  $('body').on('click', '.js-modal-close', function () {
    $('.js-modal-news').removeClass('active');
    $('.js-modal-preloader').addClass('visible');
  });

  $('body').click(function (event) {
    if (!$(event.target).closest('.js-modal-news,.js-modal-news-show').length) {
      $('body').find('.js-modal-news').removeClass('active');
      bodyScrollLock(false);
    }
  });
}

/**
 * Set News modal Height
 */
function newsModalHeight() {
  let sliderHeight = $('.js-news-slider').find('.slick-track').outerHeight();

  document.documentElement.style.setProperty(
    '--newsSliderHeight',
    `${Math.ceil(sliderHeight)}px`
  );
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight({
    byRow: false,
    property: 'height',
  });
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  $('.menu-icon').on('click', function () {
    $(this).toggleClass('is-active');
    $('.js-header').toggleClass('active');

    if ($(this).hasClass('is-active')) {
      bodyScrollLock(true);
    } else {
      bodyScrollLock(false);
    }
  });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  /**
   * Smooth scroll to anchor section
   */

  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    let $target = $($.attr(this, 'href'));
    if (!$(this).hasClass('js-scroll-down') && $target.length) {
      $('html, body').animate(
        {
          scrollTop: $target.offset().top,
        },
        1000,
        'swing'
      );
    }
  });

  $('.top-bar').on('click', 'a', function () {
    $('.menu-icon').removeClass('is-active');
    $('.js-header').removeClass('active');
  });

  resizeVideo();

  /**
   * Init functions
   */
  headerHover();

  stageScrollDown();

  historySlider();

  terminalSlider();

  grainGridItemHeight();

  masonryGrainsGrid();

  teamFilter();

  toggleOffersItem();

  loadMoreOffers();

  newsSlider();

  newsModalHeight();

  newsModalContent();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  headerHover();
  resizeVideo();
  bodyScrollLock(false);
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
